import React from "react"
import Layout from "../components/layout"
import MailchimpForm from "../components/mailchimp_form"
import { css } from "@emotion/core"

const PressClips = () => (
  <Layout>
    <MailchimpForm />
    <div
      css={css`
        width: 100%;
        @media (min-width: 600px) {
          width: 80%;
        }
        margin-right: 40px;
        padding-right: 40px;
      `}
    >
      <h1
        css={css`
          margin-bottom: 20px;
        `}
      >
        Press Clips
      </h1>
      <br />
      <h2>O'Reilly Media</h2> 
      <p>Hands-On Code Review - November 10, 2021 and January 11, 2022</p>
      <p>This live course was designed to get attendees better at writing software and solving problems.</p>
      <br />
      <h2>freeCodeCamp</h2>
      <ul>
        <li>
          <a href="https://www.freecodecamp.org/news/javascript-object-keys-tutorial-how-to-use-a-js-key-value-pair/">
            JavaScript Object Keys Tutorial – How to Use a JS Key-Value Pair
          </a>
        </li>
        <li>
          <a href="https://www.freecodecamp.org/news/css-font-size-tutorial-how-to-change-text-size-in-html/">
            CSS Font Size Tutorial – How to Change Text Size in HTML
          </a>
        </li>
        <li>
          <a href="https://www.freecodecamp.org/news/the-python-sleep-function-how-to-make-python-wait-a-few-seconds-before-continuing-with-example-commands/">
            The Python Sleep Function – How to Make Python Wait A Few Seconds
            Before Continuing, With Example Commands
          </a>
        </li>

        <li>
          <a href="https://www.freecodecamp.org/news/python-unique-list-how-to-get-all-the-unique-values-in-a-list-or-array/">
            Python Unique List – How to Get all the Unique Values in a List or
            Array
          </a>
        </li>

        <li>
          <a href="https://www.freecodecamp.org/news/when-are-you-done-with-a-programming-problem/">
            How to Know When You've Learned Everything You Can From a
            Programming Problem
          </a>
        </li>

        <li>
          <a href="https://www.freecodecamp.org/news/arrow-function-javascript-tutorial-how-to-declare-a-js-function-with-the-new-es6-syntax/">
            Arrow Function JavaScript Tutorial – How to Declare a JS Function
            with the New ES6 Syntax
          </a>
        </li>

        <li>
          <a href="https://www.freecodecamp.org/news/json-comment-example-how-to-comment-in-json-files/">
            JSON Comment Example — How to Comment in JSON Files
          </a>
        </li>
        <li>
          <a href="https://bit.ly/2YKWtKA">
            How to Add an Image URL to Your Div
          </a>
        </li>
        <li>
          <a href="https://www.freecodecamp.org/news/inline-css-guide-how-to-style-an-html-tag-directly/">
            Inline CSS Guide – How to Style an HTML Tag Directly
          </a>
        </li>
        <li>
          <a href="https://www.freecodecamp.org/news/do-you-solve-programming-problems-or-complete-exercises-the-difference-matters/">
            Do You Solve Programming Problems or Complete Exercises? (The
            Difference Matters.)
          </a>
        </li>
        <li>
          <a href="https://www.freecodecamp.org/news/how-to-get-unstuck/">
            How to Get Unstuck When You Hit a Programming Wall
          </a>
        </li>
        <li>
          <a href="https://www.freecodecamp.org/news/how-to-be-a-great-programmer-34939494996d/">
            How to Be a Great Programmer
          </a>
        </li>

        <li>
          <a href="https://www.freecodecamp.org/news/the-one-question-i-shouldve-asked-before-learning-to-program-17b6d3a629b7/">
            The One Question I Should’ve Asked Before Learning to Program
          </a>
        </li>
      </ul>

      <br />
      <h2>The Startup</h2>
      <ul>
        <li>
          <a href="https://medium.com/swlh/who-benefits-from-remote-work-495cdff86b3">
            Who Benefits from Remote Work?
          </a>
        </li>
        <li>
          <a href="https://medium.com/swlh/effective-tips-for-working-remotely-23cc290a8c08">
            Effective Tips for Working Remotely
          </a>
        </li>
        <li>
          <a href="https://medium.com/swlh/the-business-of-busyness-f8bc26409a4f">
            The Business of Busyness
          </a>
        </li>
      </ul>

      <br />
     
    </div>
  </Layout>
)

export default PressClips
